<template>
  <div>
    <b-container>
      <b-row>
        <h1 id="headline" class="page-header mr-auto">
          Active Users
          <b-button variant="link" class="info-link" @click="showUsersAlert=true">
            <font-awesome-icon icon="info-circle" size="2x"/>
          </b-button>
        </h1>
        <div class="align-self-center">
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text>
                <font-awesome-icon icon="search"/>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input v-model="filter" debounce="500" placeholder="Search" type="text"></b-form-input>
          </b-input-group>
        </div>
        <button id="btncreateuser" class="btn btn-primary float-right align-self-center ml-3" v-b-modal="'create-user-modal'">
          <font-awesome-icon icon="plus-circle"/>
          create user
        </button>
      </b-row>
    </b-container>

    <b-alert v-model="showUsersAlert" dismissible variant="secondary">
      <p>
        <font-awesome-icon icon="info-circle" class="mr-1"/>
        <strong>Information</strong></p>
      <p>This is the active users section. Here you can see and manage active ScreenCheck Portal users.</p>
      <p>If you want to change a users name, e-mail address, notes or roles press the
        <font-awesome-icon icon="ellipsis-v"/>
        button in the user's row on the right of the table and
        select <strong>edit</strong>.
      </p>
      <p>
        Note that a user's name and email address can only be edited as long as the user is not yet linked to a Metso account.
      </p>
      <p>You can deactivate a user by pressing the <strong>deactivate</strong> button in the same menu. The user will not
        be able to log in or synchronize reports if deactivated.</p>
      <p>To create a new user press the <strong>create user</strong> button on the top right.</p>
      <p>The roles assigned to a user defines the permissions the user has in the system. A <strong>User</strong> can only
        see and manage his or her own reports and devices. A <strong>View All</strong> user can see the reports created
        by all users of ScreenCheck portal. An <strong>Administrator</strong> is able to see and modify all users,
        authorize new users, modify screens, reports and meta data.</p>
      <p class="mb-0">The users list can be sorted by clicking the sort icons in the column headers.</p>
    </b-alert>

    <div v-if="statusText" class="mb-3">
      {{ statusText }}
    </div>

    <UsersTable :filter="filter" :is-busy="isBusy" :users='activeUsers' @editUser="editUser" @toggleActive="showUserStateBox"></UsersTable>

    <h1 class="page-header">
      Inactive Users
      <b-button variant="link" class="info-link" @click="showHiddenUsersAlert=true">
        <font-awesome-icon icon="info-circle" size="2x"/>
      </b-button>
    </h1>
    <b-alert v-model="showHiddenUsersAlert" dismissible variant="secondary">
      <p>
        <font-awesome-icon icon="info-circle" class="mr-1"/>
        <strong>Information</strong></p>
      <p>This is the inactive users section. Here you can see and manage inactive ScreenCheck Portal users.</p>
      <p>Inactive users cannot login or otherwise use ScreenCheck Portal.</p>
      <p class="mb-0">You can activate a user by pressing the <strong>activate</strong> button in the <font-awesome-icon icon="ellipsis-v"/> menu. The user will then
        be able to log in, use ScreenCheck Portal and synchronize reports.</p>
    </b-alert>

    <UsersTable :filter="filter" :is-busy="isBusy" :users='inactiveUsers' @editUser="editUser" @toggleActive="showUserStateBox"></UsersTable>

    <b-modal id="create-user-modal" ref="create-user-modal" title="Create User" @cancel="cancelEdit" @ok="submitCreate">
      <div id="createuser" class="card-body form-horizontal">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label" for="label">Name</label>

          <div class="col-sm-9">
            <input id="label" class="form-control" v-model="userInfo.label">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label" for="email">E-Mail</label>

          <div class="col-sm-9">
            <input id="email" type="email" class="form-control" v-model="userInfo.email">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label" for="notes">Notes</label>

          <div class="col-sm-9">
            <textarea id="notes" class="form-control" v-model="userInfo.notes"></textarea>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label" for="roles">Roles</label>

          <div class="col-sm-9">
            <div v-for="role in roles" :key="role.value" class="form-check">
              <input
                  type="checkbox"
                  class="form-check-input"
                  :id="role.value"
                  :value="role.value"
                  v-model="userInfo.roles"
                  @change="handleRoleChanges(role.value)"
              />
              <label class="form-check-label" :for="role.value">{{ role.label }}</label>
            </div>
          </div>
        </div>
        <b-alert v-if="userError" show variant="danger">
          {{ userError }}
        </b-alert>
      </div>
    </b-modal>
    <b-modal id="edit-user-modal" ref="edit-user-modal" :title="'Edit User ' + this.userInfo.longid" @cancel="cancelEdit" @ok="submitEdit">
      <div id="edituser" class="card-body form-horizontal">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label" for="editlabel">Name</label>

          <div class="col-sm-9">
            <input id="editlabel" v-model="userInfo.label" :disabled="editUserLinked" class="form-control">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label" for="editemail">E-Mail</label>

          <div class="col-sm-9">
            <input id="editemail" v-model="userInfo.email" class="form-control" :disabled="editUserLinked">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label" for="editnotes">Notes</label>

          <div class="col-sm-9">
            <textarea id="editnotes" v-model="userInfo.notes" class="form-control"></textarea>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label" for="editrole">Roles</label>

          <div class="col-sm-9">
            <div v-for="role in roles" :key="role.value" class="form-check">
              <input
                  type="checkbox"
                  class="form-check-input"
                  :id="role.value"
                  :value="role.value"
                  v-model="userInfo.roles"
                  @change="handleRoleChanges(role.value)"
              />
              <label class="form-check-label" :for="role.value">{{ role.label }}</label>
            </div>
          </div>
        </div>
        <b-alert v-if="userError" show variant="danger">
          {{ userError }}
        </b-alert>
      </div>
    </b-modal>
  </div>
</template>

<script>
import UsersTable from '../components/UsersTable.vue'
import { usersApi } from '@/backend'
import { CreateUserQuery, UpdateUserQuery, UserToggleActiveQuery } from '../screencheck_portal_api/src'

export default {
  name: 'UsersPage',
  components: {
    UsersTable
  },
  data: function () {
    return {
      users: [],
      statusText: null,
      isBusy: false,
      filter: '',
      showHiddenUsersAlert: false,
      showUsersAlert: false,
      editUserLinked: false,
      userInfo: {
        longid: '',
        roles: [ 'user' ],
        label: '',
        email: '',
        notes: '',
      },
      userError: '',
      roles: [
        { value: 'restricted', label: 'Restricted' },
        { value: 'user', label: 'User' },
        { value: 'viewall', label: 'View All' },
        { value: 'admin', label: 'Administrator' },
        { value: 'engineering_manager', label: 'Engineering Manager' },
        { value: 'raw_data', label: 'Raw Data' }
      ]
    }
  },
  computed: {
    activeUsers: function () {
      return this.users ? this.users.filter(user => user.status === 'active') : null
    },
    inactiveUsers: function () {
      return this.users ? this.users.filter(user => user.status !== 'active') : null
    }
  },
  mounted: function () {
    this.fetchUsers()
  },
  methods: {
    fetchUsers: function () {
      this.isBusy = true
      usersApi.getUsers((error, data) => {
        this.isBusy = false
        if (error) {
          console.error(error)
          this.statusText = 'Error transferring data'
          return
        }
        this.users = data
        this.statusText = null
      })
    },
    editUser: function (userLongid) {
      const user = this.users.find(user => user.longid === userLongid)
      if (!user) {
        console.log('User with longid ' + userLongid + ' not found')
        return
      }
      this.userInfo.longid = user.longid
      this.userInfo.roles = user.roles
      this.userInfo.label = user.label
      this.userInfo.email = user.email
      this.userInfo.notes = user.notes

      this.editUserLinked = user.linked
      this.$refs['edit-user-modal'].show()
    },
    showUserStateBox (userLongid) {
      const user = this.users.find(user => user.longid === userLongid)
      if (!user) {
        console.log('User with longid ' + userLongid + ' not found')
        return
      }

      let nextStatus = user.status === 'active' ? 'inactive' : 'active'
      let boxMsg = nextStatus === 'active' ? user.label + ' will be able to use ScreenCheck Portal again.' :  user.label + ' will not be able to use ScreenCheck Portal anymore.'

      this.$bvModal.msgBoxConfirm(boxMsg, {
        title: 'Setting user ' + nextStatus
      }).then(value => {
        if (value) {
          const userActiveQuery = UserToggleActiveQuery.constructFromObject({
            user: user.longid,
            status: nextStatus
          })
          usersApi.toggleUserActive(userActiveQuery, (error) => {
            if (error) {
              console.error('Failed toggling user active because: ' + error)
              return
            }
            // fetch Data
            this.fetchUsers()
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    cancelEdit: function () {
      this.userInfo = {
        longid: '',
        roles: [ 'user' ],
        label: '',
        email: '',
        notes: '',
      }
      this.userError = ''
    },
    submitEdit: function (ev) {
      ev.preventDefault()
      const updateQuery = UpdateUserQuery.constructFromObject({
        longid: this.userInfo.longid.replace(' ', ''),
        label: this.userInfo.label,
        email: this.userInfo.email,
        notes: this.userInfo.notes,
        roles: this.userInfo.roles
      })
      usersApi.updateUser(updateQuery, (error) => {
        if (error) {
          console.error('Updating user failed because of: ' + error)
          this.userError = 'Failed to update user'
          if (error.response.body.message) {
            this.userError += ': ' + error.response.body.message
          }
          return
        }
        this.$refs['edit-user-modal'].hide()
        // Reset User Object and Reload Users
        this.fetchUsers()
        this.cancelEdit()
      })
    },
    submitCreate: function (ev) {
      ev.preventDefault()
      const createQuery = CreateUserQuery.constructFromObject({
        label: this.userInfo.label,
        email: this.userInfo.email,
        notes: this.userInfo.notes,
        roles: this.userInfo.roles
      })

      usersApi.createUser(createQuery, (error) => {
        if (error) {
          console.error('Failed to create new user because: ' + error)
          this.userError = 'Failed to create new user'
          if (error.response.body.message) {
            this.userError += ': ' + error.response.body.message
          }
          return
        }
        this.$refs['create-user-modal'].hide()
        // Reset User Object and reload Users
        this.cancelEdit()
        this.fetchUsers()
      })
    },
    handleRoleChanges(role) {
      if (role === 'restricted') {
        this.userInfo.roles = [ 'restricted' ];
      } else {
        this.userInfo.roles = this.userInfo.roles.filter((role) => role !== 'restricted');
      }
    },
  }
}
</script>

<style lang="scss">
</style>
