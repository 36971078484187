<template>
  <div>
    <b-badge @click.prevent="showOpenReviewsModal" variant="warning" v-if="tableItems.length > 0" href="#">
      <font-awesome-icon icon="magnifying-glass" class="icon mr-1"/>
      <span v-if="tableItems.length === 1">1 Open Review</span>
      <span v-else>{{ tableItems.length }} Open Reviews</span>
    </b-badge>
    <b-modal :id="identifier" :ref="identifier" title="Open Machine File Reviews" hide-footer size="lg">
      <b-table :fields="fields" :items="tableItems" small striped class="mb-0">
        <template #cell(machineSerial)="data">
          <router-link :to="'/machine/' + data.value + '/review/' + data.item.uuid">{{ data.value }}</router-link>
        </template>
        <template #cell(timestamp)="data">
          {{ formattedTimestamp(data.value) }}
        </template>
        <template #cell(author)="data">
          {{ data.value || 'Unknown' }}
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>

import { machineFilesApi } from '@/backend'
import { DateTime } from 'luxon'

export default {
  name: 'openReviewsNotification',
  props: {
    machineSerial: {
      type: String,
      default: null
    },
    identifier: {
      type: String
    }
  },
  data: function () {
    return {
      fields: [
        { key: 'machineSerial', label: 'Machine SN', sortable: true },
        { key: 'machineType', label: 'Machine Type', sortable: true },
        { key: 'customerName', label: 'Customer', sortable: true },
        { key: 'timestamp', label: 'Date', sortable: true },
        { key: 'author', label: 'Author', sortable: true }
      ],
      tableItems: []
    }
  },
  mounted () {
    this.fetchOpenReviews()
  },
  methods: {
    fetchOpenReviews () {
      if (this.machineSerial) {
        machineFilesApi.getSpecificMachineInformationForOpenReviewsNotification(this.machineSerial, (error, data) => {
          if (error) {
            console.error('Failed fetching open reviews because: ' + error)
            this.tableItems = []
            return
          }
          this.tableItems = data
        })
      }
      else {
        machineFilesApi.getAllMachinesInformationForOpenReviewsNotification((error, data) => {
          if (error) {
            console.error('Failed fetching open reviews because: ' + error)
            this.tableItems = []
            return
          }
          this.tableItems = data
        })
      }
    },
    showOpenReviewsModal () {
      this.$bvModal.show(this.identifier)
    },
    formattedTimestamp (timestamp) {
      return DateTime.fromJSDate(timestamp).toFormat('yyyy-MM-dd HH:mm')
    }
  },
  watch: {
    $route () {
      if (this.$refs[this.identifier] && this.$refs[this.identifier].isVisible) {
        this.$bvModal.hide(this.identifier)
      }

      // On route change fetch open reviews again, to prevent stale data
      this.fetchOpenReviews()
    }
  },
}
</script>
