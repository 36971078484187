<template>
  <div>
    <h1 class="page-header">Machine File Review {{ machineSerial }}</h1>
    <div v-if="masterFile && machineFile">
      <machine-file-merger-overview :masterFile="masterFile" :machineFile="machineFile" @onMergedFileChanged="updateMergedFile"></machine-file-merger-overview>
      <div class="btn-group float-right mb-5" v-if="machineFile && userHasRole">
        <b-button @click="acceptReview" size="sm" variant="outline-dark">
          <font-awesome-icon icon="check"/>
          Save
        </b-button>
      </div>
    </div>
    <div v-else class="text-center">
      <span v-if="errorText">{{ errorText }}</span>
      <b-spinner v-else-if="!masterFile" label="Loading..." show variant="secondary"></b-spinner>
    </div>
  </div>
</template>
<script>
import MachineFileMergerOverview from '@/components/machine_file/merger/Overview.vue'
import { machineFilesApi, machinesApi } from '@/backend'
import { defaultMachineFile } from '@/utils/MachineFile'
import { EditMachineQuery } from '@/screencheck_portal_api/src'

export default {
  name: 'MachineFileReview',
  components: {
    MachineFileMergerOverview
  },
  data () {
    return {
      masterFile: null,
      machineFile: null,
      mergedFile: null,
      errorText: null
    }
  },
  mounted () {
    this.fetchMachineFileForReview()
  },
  computed: {
    machineSerial: function () {
      return this.$route.params.serial
    },
    machineFileUuid: function () {
      return this.$route.params.uuid
    },
    userHasRole: function () {
      return this.$store.getters.isUserAdmin || this.$store.getters.isUserEngineeringManager
    },
  },
  watch: {
    $route: {
      handler: function (to) {
        if (to.name === 'machine-file-review' && this.$route.params.serial !== undefined && this.$route.params.uuid !== undefined) {
          this.fetchMachineFileForReview()
        }
      }
    }
  },
  methods: {
    fetchMachineFileForReview: function () {
      this.masterFile = null
      this.machineFile = null
      this.mergedFile = null

      machineFilesApi.getMachineFileOpenForReview(this.machineSerial, this.machineFileUuid, (error, data) => {
        if (error) {
          this.errorText = 'Failed fetching machine files.'
          console.error('Failed fetching machine files because: ' + error)
          return
        }
        this.machineFile = { ...defaultMachineFile(this.machineSerial), ...data }
        this.errorText = null

        machinesApi.getMachineFileDetails(this.machineSerial, (error, data) => {
          if (error) {
            this.errorText = 'Failed fetching machine master file data'
            console.error('Failed fetching machine because: ' + error)
            return
          }
          const emptyMachineFile = defaultMachineFile(this.machineSerial)
          this.masterFile = { ...emptyMachineFile, ...data }
          this.mergedFile = { ...emptyMachineFile, ...data }
          this.errorText = null
        })
      })
    },
    acceptReview: function () {
      if (JSON.stringify(this.masterFile) !== JSON.stringify(this.mergedFile)) {
        let machine = EditMachineQuery.constructFromObject({
          serial: this.mergedFile.static.machineSerialNumber.toUpperCase(),
          type: this.mergedFile.static.machineType,
          owner: this.mergedFile.static.customerName,
          data: this.mergedFile
        }, undefined)
        machinesApi.editMachineFile(this.machineSerial, machine, (error) => {
          if (error) {
            this.errorText = 'Could not update master machine file.'
            console.error('Could not update master machine file because: ' + error)
            return
          }
          this.masterFile = this.mergedFile
          this.markMachineFileAsReviewed()
        })
      } else {
        this.markMachineFileAsReviewed()
      }
    },
    markMachineFileAsReviewed: function () {
      machineFilesApi.markMachineFileAsReviewed(this.machineFile.uuid, { 'body': {} }, (error) => {
        if (error) {
          this.errorText = 'Could not machine file as reviewed.'
          console.error('Could not machine file as reviewed because: ' + error)
          return
        }
        this.$router.push('/machine/' + this.machineSerial)
      })
    },
    updateMergedFile: function (updatedMergedFile) {
      this.mergedFile = updatedMergedFile
    }
  }
}
</script>