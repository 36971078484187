<template>
  <div>
    <b-container>
      <b-row>
        <h1 id="headline" class="page-header mr-auto">All Screens</h1>
        <div class="align-self-center">
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text>
                <font-awesome-icon icon="search"/>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input v-model="filter" debounce="500" placeholder="Search" type="text"></b-form-input>
          </b-input-group>
        </div>
        <div v-if="isUserAdmin" class="btn-toolbar float-right align-self-center ml-3">
          <b-button v-b-modal="'addMachine'" variant="outline-dark">
            <font-awesome-icon icon="plus"/>
            Add
          </b-button>
        </div>
      </b-row>
    </b-container>
    <div>
      <b-table
          id="machines-table"
          ref="machines-table"
          :busy.sync="isBusy"
          :current-page="currentPage"
          :fields="fields"
          :filter="filter"
          :items="machinesProvider"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          responsive="sm"
          show-empty
          small
          striped
      >
        <template #cell(serial)="data">
          <router-link :to="'/machine/' + encodeURIComponent(data.item.serial)">
            <font-awesome-icon icon="barcode"/>
            {{ data.item.serial }}
          </router-link>
        </template>
        <template #cell(reviewCount)="data">
          <div v-if="data.item.reviewCount > 0">
            <open-reviews-notification :machineSerial="data.item.serial" identifier="machinesNotifications"/>
          </div>
        </template>
        <template #empty="">
          <div aria-live="polite" role="alert">
            <div class="text-center my-2">
              <b-spinner v-if="isBusy" :style="'visibility: ' + (isBusy ? 'visible': 'hidden')" label="Loading..."
                         variant="secondary"></b-spinner>
              <span v-else>There are no records matching your request</span>
            </div>
          </div>
        </template>
        <template #emptyfiltered="">
          <div aria-live="polite" role="alert">
            <div class="text-center my-2">
              <span>There are no records matching your request</span>
            </div>
          </div>
        </template>
      </b-table>
      <div v-if="statusText" class="mb-3">
        {{ statusText }}
      </div>
      <div class="d-flex justify-content-between align-items-center mb-3">
      <span class="text-muted">
        {{ totalRows }} results
        <b-spinner :style="'visibility: ' + (isBusy ? 'visible': 'hidden')" label="Loading..." small
                   variant="secondary"></b-spinner>
      </span>
        <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            aria-controls="machines-table"
            class="mb-0"
            first-number
            last-number
            limit="7"
            size="sm"
        ></b-pagination>
        <b-form inline>
          <span class="text-muted">Show</span>
          <b-form-select v-model="perPage" :options="perPageOptions" class="ml-2" size="sm"></b-form-select>
        </b-form>
      </div>
    </div>

    <b-modal id="addMachine" title="Add" @cancel="resetMachineModal" @ok="addMachine">
      <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="serial">S/N</label>
        <div class="col-sm-10">
          <div class="input-group">
            <div class="input-group-prepend"><span class="input-group-text"><font-awesome-icon icon="barcode"/></span>
            </div>
            <input id="serial" v-model="modalSerial" class="form-control" name="serial" style="text-transform:uppercase"
                   type="text" @input="serialInput" @keypress="serialKeyPress">
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="type">Type</label>
        <div class="col-sm-10">
          <div class="input-group">
            <div class="input-group-prepend"><span class="input-group-text"><font-awesome-icon icon="tags"/></span>
            </div>
            <input id="type" v-model="modalType" class="form-control" name="type" type="text">
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="owner">Owner</label>
        <div class="col-sm-10">
          <div class="input-group">
            <div class="input-group-prepend"><span class="input-group-text"><font-awesome-icon icon="briefcase"/></span>
            </div>
            <input id="owner" v-model="modalOwner" class="form-control" name="owner" type="text">
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

import { machinesApi } from '@/backend'
import { EditMachineQuery } from '@/screencheck_portal_api/src'
import { defaultMachineFile } from '@/utils/MachineFile'
import OpenReviewsNotification from '@/components/machine_file/OpenReviewsNotification.vue'

export default {
  name: 'MachinesPage',
  components: { OpenReviewsNotification },
  data: function () {
    return {
      statusText: null,
      totalRows: 0,
      isBusy: false,
      fields: [
        { key: 'serial', label: 'Screen Serial', sortable: true },
        { key: 'type', label: 'Screen Type', sortable: true },
        { key: 'owner', label: 'Screen Owner', sortable: true },
        { key: 'reportCount', label: 'Number of Reports', sortable: true },
        { key: 'fileCount', label: 'Number of Files', sortable: true },
        { key: 'reviewCount', label: 'Open Reviews', sortable: true }
      ],
      filter: '',
      sortBy: null,
      sortDesc: false,
      perPage: 25,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      modalSerial: '',
      modalType: '',
      modalOwner: '',
    }
  },
  computed: {
    isUserAdmin: function () {
      return this.$store.getters.isUserAdmin
    },
  },
  methods: {
    addMachine: function () {
      let machine = EditMachineQuery.constructFromObject({
        serial: this.modalSerial.trim(),
        type: this.modalType,
        owner: this.modalOwner,
        data: defaultMachineFile(this.modalSerial.trim(), this.modalType, this.modalOwner)
      })
      machinesApi.addMachines(machine, (error) => {
        this.isBusy = false
        if (error) {
          console.error(error)
          this.statusText = 'Error creating machines'
          return
        }
        this.resetMachineModal()
        this.$refs['machines-table'].refresh()
      })
    },
    resetMachineModal: function () {
      this.modalSerial = ''
      this.modalType = ''
      this.modalOwner = ''
    },
    serialInput: function () {
      if (this.modalSerial.match(/[^a-zA-Z0-9 .-]/g)) {
        this.modalSerial = this.modalSerial.toUpperCase().replace(/[^A-Z0-9 .-]/g, '')
      }
    },
    serialKeyPress: function (ev) {
      if (String.fromCharCode(ev.which).match(/[^a-zA-Z0-9 .-]/g) && event.keyCode !== 46 && event.keyCode !== 8) {
        ev.preventDefault()
      }
    },
    machinesProvider: function (ctx, callback) {
      let query = {
        filter: ctx.filter,
        sortBy: ctx.sortBy,
        sortDesc: ctx.sortDesc,
        currentPage: ctx.currentPage,
        perPage: ctx.perPage
      }
      this.isBusy = true
      machinesApi.getAllMachines(query, (error, data) => {
        this.isBusy = false
        if (error) {
          console.error(error)
          this.statusText = 'Error transferring machines'
          callback([])
          return
        }
        this.totalRows = data.totalRows
        callback(data.machines)
      })
      // Required to use callback
      return null
    }
  }
}
</script>

<style lang="scss">
</style>
