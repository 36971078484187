<template>
  <div>
    <div class="row">
      <machine-file-merger-data :merged-data="mergedFile.static" :machine-data="machineFile.static" :master-data="masterFile.static" :data-keys="staticKeys" data-name="Static Data" @onMergedFileChanged="onMergedFileChanged"/>
    </div>
    <div class="row">
      <machine-file-merger-data :merged-data="mergedFile.fixed" :machine-data="machineFile.fixed" :master-data="masterFile.fixed" :data-keys="weightKeys" data-name="Weight Data" @onMergedFileChanged="onMergedFileChanged"/>
    </div>
    <div class="row">
      <machine-file-merger-data :merged-data="mergedFile.fixed?.process" :machine-data="machineFile.fixed?.process" :master-data="masterFile.fixed?.process" :data-keys="processKeys" data-name="Application Data" @onMergedFileChanged="onMergedFileChanged"/>
    </div>
    <div class="row" v-if="masterFile.fixed?.media?.topDeck || machineFile.fixed?.media?.topDeck">
      <machine-file-merger-data :merged-data="mergedFile.fixed?.media?.topDeck" :machine-data="machineFile.fixed?.media?.topDeck" :master-data="masterFile.fixed?.media?.topDeck" :data-keys="mediaKeysTop" data-name="Media Data - Top Deck" @onMergedFileChanged="onMergedFileChanged"/>
    </div>
    <div class="row" v-if="masterFile.fixed?.media?.upperMidDeck || machineFile.fixed?.media?.upperMidDeck">
      <machine-file-merger-data :merged-data="mergedFile.fixed?.media?.upperMidDeck" :machine-data="machineFile.fixed?.media?.upperMidDeck" :master-data="masterFile.fixed?.media?.upperMidDeck" :data-keys="mediaKeysUpperMid" data-name="Media Data - Upper Mid Deck" @onMergedFileChanged="onMergedFileChanged"/>
    </div>
    <div class="row" v-if="masterFile.fixed?.media?.lowerMidDeck || machineFile.fixed?.media?.lowerMidDeck">
      <machine-file-merger-data :merged-data="mergedFile.fixed?.media?.lowerMidDeck" :machine-data="machineFile.fixed?.media?.lowerMidDeck" :master-data="masterFile.fixed?.media?.lowerMidDeck" :data-keys="mediaKeysLowerMid" data-name="Media Data - Lower Mid Deck" @onMergedFileChanged="onMergedFileChanged"/>
    </div>
    <div class="row" v-if="masterFile.fixed?.media?.bottomDeck || machineFile.fixed?.media?.bottomDeck">
      <machine-file-merger-data :merged-data="mergedFile.fixed?.media?.bottomDeck" :machine-data="machineFile.fixed?.media?.bottomDeck" :master-data="masterFile.fixed?.media?.bottomDeck" :data-keys="mediaKeysBottom" data-name="Media Data - Bottom Deck" @onMergedFileChanged="onMergedFileChanged"/>
    </div>
    <div class="row">
      <machine-file-merger-data :merged-data="mergedFile.fixed" :machine-data="machineFile.fixed" :master-data="masterFile.fixed" :data-keys="suspensionKeys" data-name="Suspension Data" @onMergedFileChanged="onMergedFileChanged"/>
    </div>
    <div class="row">
      <machine-file-merger-data :merged-data="mergedFile.fixed" :machine-data="machineFile.fixed" :master-data="masterFile.fixed" :data-keys="lubricationKeys" data-name="Lubrication Data" @onMergedFileChanged="onMergedFileChanged"/>
    </div>
    <div class="row">
      <machine-file-merger-data :merged-data="mergedFile.fixed?.screenSuspensionFeedDischarge" :machine-data="machineFile.fixed?.screenSuspensionFeedDischarge" :master-data="masterFile.fixed?.screenSuspensionFeedDischarge" :data-keys="screenSuspensionFeedDischargeKeys" data-name="Screen Suspension Feed Discharge" @onMergedFileChanged="onMergedFileChanged"/>
    </div>
    <div class="row" v-if="masterFile.fixed?.isolationFramePresent?.toUpperCase() === 'YES, INSTALLED' || machineFile.fixed?.isolationFramePresent?.toUpperCase() === 'YES, INSTALLED'">
      <machine-file-merger-data :merged-data="mergedFile.fixed?.isolationFrameFeedDischarge" :machine-data="machineFile.fixed?.isolationFrameFeedDischarge" :master-data="masterFile.fixed?.isolationFrameFeedDischarge" :data-keys="isolationFrameFeedDischargeKeys" data-name="Isolation Frame Feed Discharge" @onMergedFileChanged="onMergedFileChanged"/>
    </div>
    <div class="row">
      <machine-file-merger-data :merged-data="mergedFile.fixed?.motor" :machine-data="machineFile.fixed?.motor" :master-data="masterFile.fixed?.motor" :data-keys="motorKeys" data-name="Motor Data" @onMergedFileChanged="onMergedFileChanged"/>
    </div>
    <div class="row">
      <machine-file-merger-data :merged-data="mergedFile.fixed?.motor?.motorA" :machine-data="machineFile.fixed?.motor?.motorA" :master-data="masterFile.fixed?.motor?.motorA" :data-keys="motorAKeys" data-name="Motor A Data" @onMergedFileChanged="onMergedFileChanged"/>
    </div>
    <div class="row" v-if="masterFile.fixed?.motor?.motorB || machineFile.fixed?.motor?.motorB">
      <machine-file-merger-data :merged-data="mergedFile.fixed?.motor?.motorB" :machine-data="machineFile.fixed?.motor?.motorB" :master-data="masterFile.fixed?.motor?.motorB" :data-keys="motorBKeys" data-name="Motor B Data" @onMergedFileChanged="onMergedFileChanged"/>
    </div>
    <div class="row">
      <machine-file-merger-data :merged-data="mergedFile.fixed" :machine-data="machineFile.fixed" :master-data="masterFile.fixed" :data-keys="revisionKeys" data-name="Revision Data" @onMergedFileChanged="onMergedFileChanged"/>
    </div>
  </div>
</template>

<script>
import MachineFileMergerData from '@/components/machine_file/merger/Data.vue'

export default {
  name: 'MachineFileMergerOverview',
  components: { MachineFileMergerData },
  props: {
    masterFile: {
      type: Object,
      required: true,
    },
    machineFile: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      mergedFile: JSON.parse(JSON.stringify(this.masterFile)),
      staticKeys: {},
      weightKeys: {},
      processKeys: {},
      mediaKeysTop: {},
      mediaKeysUpperMid: {},
      mediaKeysLowerMid: {},
      mediaKeysBottom: {},
      suspensionKeys: {},
      lubricationKeys: {},
      screenSuspensionFeedDischargeKeys: {},
      isolationFrameFeedDischargeKeys: {},
      motorKeys: {},
      motorAKeys: {},
      motorBKeys: {},
      revisionKeys: {},
      errorText: null
    }
  },
  mounted() {
    this.getKeys();
  },
  methods: {
    onMergedFileChanged() {
      this.$emit('onMergedFileChanged', this.mergedFile);
    },
    filterKeysByWord(object, keyword) {
      const filteredObject = {};
      for (const key in object) {
        if (key.includes(keyword)) {
          filteredObject[key] = object[key];
        }
      }
      return Object.keys(filteredObject);
    },
    filterSuspensionKeys(object) {
      const filteredObject = {};
      for (const key in object) {
        if (typeof object[key] !== 'object' && (
            key.toLowerCase().includes('suspension') ||
            key.toLowerCase().includes('isolation'))) {
          filteredObject[key] = object[key];
        }
      }
      return Object.keys(filteredObject);
    },
    filterLubricationKeys(object) {
      const filteredObject = {};
      for (const key in object) {
        if (typeof object[key] !== 'object' && (
            key.toLowerCase().includes('lubrication') ||
            key.toLowerCase().includes('greasing'))) {
          filteredObject[key] = object[key];
        }
      }
      return Object.keys(filteredObject);
    },
    filterKeys(object) {
      const filteredObject = {};
      for (const key in object) {
        if (typeof object[key] !== 'object' || Array.isArray(object[key])) {
          filteredObject[key] = object[key];
        }
      }
      return Object.keys(filteredObject);
    },
    getKeys() {
      this.staticKeys = [...new Set([
        ...Object.keys(this.mergedFile.static),
        ...Object.keys(this.machineFile.static)
      ])]
      this.weightKeys = [...new Set([
        ...this.filterKeysByWord(this.mergedFile.fixed, 'weight'),
        ...this.filterKeysByWord(this.machineFile.fixed, 'weight')
      ])]
      this.processKeys = [...new Set([
        ...Object.keys(this.mergedFile.fixed.process),
        ...Object.keys(this.machineFile.fixed.process)
      ])]
      this.mediaKeysTop = [...new Set([
        ...Object.keys(this.mergedFile.fixed.media.topDeck),
        ...Object.keys(this.machineFile.fixed.media.topDeck)
      ])]
      this.mediaKeysUpperMid = [...new Set([
        ...Object.keys(this.mergedFile.fixed.media.upperMidDeck),
        ...Object.keys(this.machineFile.fixed.media.upperMidDeck)
      ])]
      this.mediaKeysLowerMid = [...new Set([
        ...Object.keys(this.mergedFile.fixed.media.lowerMidDeck),
        ...Object.keys(this.machineFile.fixed.media.lowerMidDeck)
      ])]
      this.mediaKeysBottom = [...new Set([
        ...Object.keys(this.mergedFile.fixed.media.bottomDeck),
        ...Object.keys(this.machineFile.fixed.media.bottomDeck)
      ])]
      this.suspensionKeys = [...new Set([
        ...this.filterSuspensionKeys(this.mergedFile.fixed),
        ...this.filterSuspensionKeys(this.machineFile.fixed)
      ])]
      this.lubricationKeys = [...new Set([
        ...this.filterLubricationKeys(this.mergedFile.fixed),
        ...this.filterLubricationKeys(this.machineFile.fixed)
      ])]
      this.screenSuspensionFeedDischargeKeys = [...new Set([
        ...Object.keys(this.mergedFile.fixed.screenSuspensionFeedDischarge),
        ...Object.keys(this.machineFile.fixed.screenSuspensionFeedDischarge)
      ])]
      this.isolationFrameFeedDischargeKeys = [...new Set([
        ...Object.keys(this.mergedFile.fixed.isolationFrameFeedDischarge),
        ...Object.keys(this.machineFile.fixed.isolationFrameFeedDischarge)
      ])]
      this.motorKeys = [...new Set([
        ...this.filterKeys(this.mergedFile.fixed.motor),
        ...this.filterKeys(this.machineFile.fixed.motor)
      ])]
      this.motorAKeys = [...new Set([
        ...Object.keys(this.mergedFile.fixed.motor.motorA),
        ...Object.keys(this.machineFile.fixed.motor.motorA)
      ])]
      this.motorBKeys = [...new Set([
        ...Object.keys(this.mergedFile.fixed.motor.motorB),
        ...Object.keys(this.machineFile.fixed.motor.motorB)
      ])]
      this.revisionKeys = [...new Set([
        ...this.filterKeysByWord(this.mergedFile.fixed, 'Revision'),
        ...this.filterKeysByWord(this.machineFile.fixed, 'Revision')
      ])]
    }
  },
};
</script>

<style lang="scss">
</style>
