<template>
  <div>
    <div v-if="machine.openReviews.count > 0" class="float-right">
      <open-reviews-notification :machineSerial="machineSerial" identifier="machineDetailsNotifications"/>
    </div>
    <h1 class="page-header">Machine File {{ machineSerial }} </h1>
    <div v-if="machineFile" class="row">
      <div class="col-md-6">
        <static-data :data="machineFile"></static-data>
        <weight-data :data="machineFile"></weight-data>
        <application-data :data="machineFile"></application-data>
        <media-data :data="machineFile"></media-data>
      </div>
      <div class="col-md-6">
        <suspension-data :data="machineFile"></suspension-data>
        <lubrication-data :data="machineFile"></lubrication-data>
        <motor-data :data="machineFile"></motor-data>
        <revision-data :data="machineFile"></revision-data>
      </div>
    </div>
    <div v-else class="text-center">
      <span v-if="errorText">{{ errorText }}</span>
      <b-spinner v-else label="Loading..." show variant="secondary"></b-spinner>
    </div>
  </div>
</template>

<script>
import staticData from '@/components/machine_file/StaticData.vue'
import weightData from '@/components/machine_file/WeightData.vue'
import applicationData from '@/components/machine_file/ApplicationData.vue'
import mediaData from '@/components/machine_file/MediaData.vue'
import suspensionData from '@/components/machine_file/SuspensionData.vue'
import lubricationData from '@/components/machine_file/LubricationData.vue'
import motorData from '@/components/machine_file/MotorData.vue'
import revisionData from '@/components/machine_file/RevisionData.vue'
import { machinesApi } from '@/backend'
import OpenReviewsNotification from '@/components/machine_file/OpenReviewsNotification.vue'

export default {
  name: 'MachineDetailsPage',
  components: {
    OpenReviewsNotification,
    staticData,
    weightData,
    applicationData,
    mediaData,
    suspensionData,
    lubricationData,
    motorData,
    revisionData
  },
  data () {
    return {
      machine: null,
      machineFile: null,
      errorText: null
    }
  },
  mounted () {
    this.fetchMachine()
  },
  computed: {
    machineSerial: function () {
      return this.$route.params.serial
    }
  },
  watch: {
    $route: {
      handler: function (to) {
        if (to.name === 'machine' && this.$route.params.serial !== undefined) {
          this.fetchMachine()
        }
      }
    }
  },
  methods: {
    fetchMachine: function () {
      machinesApi.getMachine(this.machineSerial, (error, data) => {
        if (error) {
          this.errorText = 'Failed fetching machine data'
          console.error('Failed fetching machine because: ' + error)
          return
        }
        this.machine = data
        this.errorText = null
        this.fetchMachineFileDetails()
      })
    },
    fetchMachineFileDetails: function () {
      machinesApi.getMachineFileDetails(this.machineSerial, (error, data) => {
        if (error) {
          this.errorText = 'Failed fetching machine file data'
          console.error('Failed fetching machine because: ' + error)
          return
        }
        if (Object.keys(data).length === 0) {
          this.errorText = 'No machine file data available'
          return
        }
        this.machineFile = data
        this.errorText = null
      })
    }
  }
}
</script>

<style lang="scss">
</style>